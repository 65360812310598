.DatepickerContainer {
  margin: auto;
}

.DatepickerContainer * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently */  
}

.datepicker .selected-date:focus {
  outline: none;
}

.selected-date {
  float: left;
  width: 100%;
  height: 100%;
}
.icon {
  cursor: pointer;
}

.calendar {
  z-index: 2;
  float: left;
  position: absolute;
  width: auto;
  background: #fff;
  box-shadow: 10px 10px 40px rgba(0,0,0,0.2);
  border-radius: 20px;
  overflow: hidden;
  padding: 20px 25px;
}
