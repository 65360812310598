#user-action-btn {
    width: 85px;
}

#ticker-search-btn {
    width: 100%;
}

.navbar [class*="col-sm-"] {
    padding-right: 16px;
    padding-left: 0px;
}
