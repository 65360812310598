#title {
    font-size: 250%;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    white-space: pre;
}

#search-bar {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}